import { WppIconTriangleFill, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { Task as GanttTask } from 'gantt-task-react'

import { Avatar } from 'components/common/avatar/Avatar'
import styles from 'pages/project/components/timeline/components/taskListTable/TaskListTable.module.scss'
import { TimelineDateInline } from 'pages/project/components/timeline/components/taskListTable/TimelineDateInline'
import {
  highlightRow,
  setTimelineExpandedState,
  unHighlightRow,
} from 'pages/project/components/timeline/components/taskListTable/utils'
import { TimelineAssignees } from 'pages/project/components/timeline/TimelineProvider'
import { Members } from 'types/members/members'
import { Project } from 'types/projects/projects'
import { TimelinePhase, TimelineTask } from 'types/projects/timeline'
import { fullName } from 'utils/common'

enum ExpandState {
  NONE = 'NONE',
  EXPANDED = 'EXPANDED',
  COLLAPSED = 'COLLAPSED',
}

interface TimelineTaskRowProps {
  task: GanttTask
  timelineTask: TimelineTask | TimelinePhase | TimelineAssignees
  rowHeight: number
  rowWidth: string
  members: Members[]
  project: Project
  onExpanderClick: (task: GanttTask) => void
  handleTaskOpen: (task: GanttTask) => void
  index: number
}

export const TimelineTaskRow = ({
  task,
  timelineTask,
  rowHeight,
  rowWidth,
  members,
  project,
  onExpanderClick,
  handleTaskOpen,
  index,
}: TimelineTaskRowProps) => {
  let expandState = ExpandState.NONE

  if ('tasks' in timelineTask && (timelineTask.tasks?.length ?? 0) > 0) {
    expandState = task.hideChildren === false ? ExpandState.EXPANDED : ExpandState.COLLAPSED
  }

  const handleExpand = (task: GanttTask) => {
    onExpanderClick(task)
    setTimelineExpandedState(task.id, !!task.hideChildren)
  }

  const member = !('email' in timelineTask) ? members.find(member => member.email === timelineTask.assignee) : null

  const node =
    'email' in timelineTask ? (
      <div
        className={styles.taskListCellExpandable}
        style={{
          minWidth: rowWidth,
          maxWidth: rowWidth,
        }}
        title={fullName(timelineTask.firstname, timelineTask.lastname)}
      >
        <div className={styles.taskListNameWrapper}>
          <div
            className={clsx(styles.taskListExpander, {
              [styles.empty]: expandState === ExpandState.NONE,
              [styles.expanded]: expandState === ExpandState.EXPANDED,
            })}
            onClick={() => handleExpand(task)}
          >
            <WppIconTriangleFill />
          </div>

          <Avatar
            className={styles.avatar}
            size="xs"
            src={timelineTask.avatarUrl!}
            withTooltip
            tooltipConfig={{
              placement: 'top',
            }}
            name={fullName(
              timelineTask.firstname || timelineTask.wrikeExternalFirstName!,
              timelineTask.lastname || timelineTask.wrikeExternalLastName!,
            )}
          />
          <WppTypography type={task.type === 'project' ? 's-strong' : 's-body'} className={styles.name}>
            {fullName(timelineTask.firstname, timelineTask.lastname)}
          </WppTypography>
        </div>
      </div>
    ) : (
      <div
        className={styles.taskListCell}
        data-testid="task"
        style={{
          minWidth: rowWidth,
          maxWidth: rowWidth,
        }}
        title={timelineTask.name}
      >
        <div className={styles.taskListNameWrapper}>
          <div
            className={clsx(styles.taskListExpander, {
              [styles.empty]: expandState === ExpandState.NONE,
              [styles.expanded]: expandState === ExpandState.EXPANDED,
            })}
            data-testid="expand-button"
            onClick={() => handleExpand(task)}
          >
            <WppIconTriangleFill />
          </div>

          <WppTypography
            type={task.type === 'project' ? 's-strong' : 's-body'}
            className={styles.name}
            data-testid="task-name"
            onClick={() => handleTaskOpen(task)}
          >
            {timelineTask.name}
          </WppTypography>

          <div className={styles.actions}>
            {timelineTask.assignee && (
              <Avatar
                className={styles.avatar}
                size="xs"
                src={member?.avatarUrl!}
                withTooltip
                tooltipConfig={{
                  placement: 'top',
                }}
                name={fullName(
                  member?.firstname || member?.wrikeExternalFirstName!,
                  member?.lastname || member?.wrikeExternalLastName!,
                )}
              />
            )}
            {!timelineTask.startDate && <TimelineDateInline project={project} timelineItem={timelineTask} />}
          </div>
        </div>
      </div>
    )

  return (
    <div
      className={styles.taskListTableRow}
      style={{ height: rowHeight }}
      onMouseEnter={() => highlightRow(index)}
      onMouseLeave={() => unHighlightRow(index)}
    >
      {node}
    </div>
  )
}
